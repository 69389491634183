import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ProjectConfiguration } from '@models/ProjectConfiguration';
import { PARSING_FORMAT_TEMPLATES, ParsingFormat, ParsingFormatFactory, parsingFormatFromId, ParsingFormatTemplate } from '@models/parsing-formats/ParsingFormat';
import { Canon } from '@models/VerseReference';
import { ProjectTextStyleDirective } from '../project-text-style.directive';
import { ParsingTestDisplayComponent } from '../parsing-test-display/parsing-test-display.component';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-parsing-format-editor',
  standalone: true,
  imports: [CommonModule,
    ProjectTextStyleDirective,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatStepperModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatSelectModule,
    ParsingTestDisplayComponent],
  templateUrl: './parsing-format-editor.component.html',
  styleUrl: './parsing-format-editor.component.scss'
})
export class ParsingFormatEditorComponent implements AfterViewInit {
  @ViewChild('stepper') stepper!: MatStepper; // Reference to MatStepper
  readonly dialogRef = inject(MatDialogRef<ParsingFormatEditorComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);
  private _formBuilder = inject(FormBuilder);
  project: ProjectConfiguration;
  canon: Canon;
  template_id: string | undefined;
  parsingFormatTemplate: ParsingFormatTemplate | undefined;
  parsingFormat: ParsingFormat | undefined;
  translationsGroup: FormGroup;
  initialPanel: FormGroup;
  id: string = '';
  strings: any = {};

  constructor() {
    this.canon = this.data.canon;
    this.project = this.data.project;
    if (this.data.id) {
      this.id = this.data.id;
      this.parsingFormat = this.project.getParsingFormat(this.id);
      if (this.parsingFormat) {
        this.template_id = this.parsingFormat.template;
        console.log("About to select template");
        // this.selectTemplate(this.template_id);
      }
    }

    this.translationsGroup = this._formBuilder.group({});
    this.initialPanel = this._formBuilder.group({});
    this.initialPanel = this._formBuilder.group({
      id: new FormControl('', Validators.required),
      template: new FormControl('', Validators.required)
    });
  }

  ngAfterViewInit() {
    if (this.parsingFormat) {
      this.initialPanel.patchValue({
        id: this.parsingFormat.id,
        template: [this.parsingFormat.template]
      });
    }
  }


  onDismiss(): void {
    this.dialogRef.close();
  }

  accept(): void {
    if (this.parsingFormat) {
      this.dialogRef.close(this.parsingFormat);
    }
  }

  afterFirstPanel() {
    this.id = this.initialPanel.get('id')?.value;
    this.template_id = this.initialPanel.get('template')?.value[0];
    if (this.template_id) {
      this.selectTemplate(this.template_id);
    }
  }

  get types(): ParsingFormatTemplate[] {
    return PARSING_FORMAT_TEMPLATES.filter((value: ParsingFormatTemplate) => value.canon === this.canon);
  }

  selectTemplate(id: string) {
    this.parsingFormatTemplate = parsingFormatFromId(id);
    if (this.parsingFormatTemplate) {
      this.translationsGroup = this._formBuilder.group({});
      this.stringKeys.forEach((key: string) => {

        let value = this.parsingFormat ? this.parsingFormat.getString(key) : this.parsingFormatTemplate?.placeholders[key];
        this.translationsGroup.addControl(key, this._formBuilder.control(value, Validators.required));
      });
    }
  }

  get stringKeys(): string[] {
    if (this.parsingFormatTemplate) {
      return Object.keys(this.parsingFormatTemplate.strings);
    }
    return [];
  }

  // translationValue(key: string) {
  //   if (this.parsingFormat) {
  //     // console.log("Returning from parsing format", this.parsingFormat.getString(key));
  //     return this.parsingFormat.getString(key);
  //   } else {
  //     return this.parsingFormatTemplate?.placeholders[key];
  //   }
  // }

  createParsingFormat() {
    this.strings = {};
    this.stringKeys.forEach((key: string) => {
      this.strings[key] = this.translationsGroup.get(key)?.value;
    });
    if (this.parsingFormatTemplate) {
      this.parsingFormat = ParsingFormatFactory.createParsingFormat(this.id, this.project.replaceNumerals.bind(this.project), this.parsingFormatTemplate.id, this.strings);
    }
  }

}
