import { Component, Input, OnInit } from '@angular/core';
import { Gloss } from '@models/Gloss';
import { Verse } from '@models/Verse';
import { MatCardModule } from '@angular/material/card';
import { PhraseGlossLocation } from '@models/gloss-locations';
import { CommonModule } from '@angular/common';
import { GlossSelectorLineComponent } from '../gloss-selector-line/gloss-selector-line.component';

@Component({
  selector: 'app-phrase-gloss-display',
  standalone: true,
  imports: [CommonModule, MatCardModule, GlossSelectorLineComponent],
  templateUrl: './phrase-gloss-display.component.html',
  styleUrl: './phrase-gloss-display.component.scss'
})
export class PhraseGlossDisplayComponent implements OnInit {
  @Input() verse!: Verse;
  @Input() glosses!: Gloss[];

  constructor() {
  }

  ngOnInit() {
  }

}
