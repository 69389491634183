<div class="container">
<button mat-button class="reference" (click)="verseReferenceDialog()" matTooltip="Open verse selection dialog">{{formattedVerseReference}}</button>

<div class="button_cluster">
    <button mat-icon-button matTooltip="Previous Verse" aria-label="Previous verse" (click)="previousVerse()">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button matTooltip="Next Verse" aria-label="Next verse" (click)="nextVerse()">
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>

<div class="button_cluster">
    <button mat-icon-button matTooltip="Previous verse with words that I haven't voted on" aria-label="Previous verse with words that I haven't voted on" (click)="previousVerseWithoutMyVote()">
        <mat-icon>first_page</mat-icon>
    </button>
    <mat-icon>person</mat-icon>
    <button mat-icon-button matTooltip="Next verse with words that I haven't voted on" aria-label="Next verse with words that I haven't voted on" (click)="nextVerseWithoutMyVote()">
        <mat-icon>last_page</mat-icon>
    </button>
</div>

<div class="button_cluster">
    <button mat-icon-button matTooltip="Next verse with words that don't have votes from anyone" aria-label="Next verse with words that don't have votes from anyone" (click)="previousVerseWithoutAnyVotes()">
        <mat-icon>first_page</mat-icon>
    </button>
    <mat-icon>people</mat-icon>
    <button mat-icon-button matTooltip="Previous verse with words that don't have votes from anyone" aria-label="Previous verse with words that don't have votes from anyone" (click)="nextVerseWithoutAnyVotes()">
        <mat-icon>last_page</mat-icon>
    </button>
</div>

<!-- 2024-08-30: The verse object isn't triggering a redraw in this component, and I don't have the energy to work on that right now -->
<!-- <app-verse-progress-indicator class="onright"></app-verse-progress-indicator> -->

</div>