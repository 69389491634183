<h2 mat-dialog-title>New Gloss</h2>
<mat-dialog-content>
  <form (ngSubmit)="accept()" #myForm="ngForm">
    <!-- <p>{{data.type}}</p> -->
@if (data.type === 'word' || data.type === 'wordplusmarkdown') {
  <mat-form-field>
    <mat-label>Gloss</mat-label>
    <input matInput [(ngModel)]="gloss" cdkFocusInitial [ngModelOptions]="{standalone: true}" [appProjectTextStyle]="synchronizerService.currentProject"/>
  </mat-form-field>
}
@if (data.type === 'markdown' || data.type === 'wordplusmarkdown') {
    <mat-form-field>
    <mat-label>Markdown</mat-label>
    <input matInput [(ngModel)]="markdown" [ngModelOptions]="{standalone: true}" [appProjectTextStyle]="synchronizerService.currentProject"/>
  </mat-form-field>
  <p>Preview:</p>
  <p [innerHTML]="markdown() | markdown"></p>
}
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onDismiss()">Dismiss</button>
  <button mat-flat-button (click)="accept()">Add Gloss</button>
</mat-dialog-actions>
