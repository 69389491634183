<h2 mat-dialog-title *ngIf="id === undefined">New Parsing Format</h2>
<h2 mat-dialog-title *ngIf="id">{{id}}</h2>
<mat-dialog-content>

    
<mat-stepper linear #stepper>
    <mat-step>
      <ng-template matStepLabel>Choose a template</ng-template>
      <form [formGroup]="initialPanel">

        <mat-form-field>
          <mat-label>Create a name for this parsing style</mat-label>
          <input matInput formControlName="id"/>
        </mat-form-field>

        <p>Choose one of these types of templates for your glosses. After you click on a template, you'll be prompted to translate the strings for it.</p>
        <mat-selection-list formControlName="template" [multiple]="false">
          @for (t of types; track t.id) {
            <mat-list-option [value]="t.id">
              <h3 matListItemTitle>{{t.name}}</h3>
              <p matListItemLine>{{t.description}}</p>
            </mat-list-option>
          }
        </mat-selection-list>
        <button mat-button (click)="onDismiss()" type="button">Dismiss</button>
        <button mat-button matStepperNext (click)="afterFirstPanel()" [disabled]="initialPanel.invalid" type="button">Next</button>
      </form>
    </mat-step>
    <mat-step>
      <form [formGroup]="translationsGroup">
        <ng-template matStepLabel>Translate</ng-template>
        @for ( key of stringKeys; track key ) {
            <mat-form-field class="translation">
                <mat-label>{{parsingFormatTemplate?.strings[key]}}</mat-label>
                <!-- <input matInput [appProjectTextStyle]="project" [value]="translationValue(key)" [formControlName]="key"/> -->
                <input matInput [appProjectTextStyle]="project" [formControlName]="key"/>
            </mat-form-field>
        }
      </form>        
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext (click)="createParsingFormat()">Next</button>
      </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Test</ng-template>
        <p>Have a look below at what your translations have produced. If it doesn't look right, go back to the “Translate” tab to fix it.</p>
        <h2>Nouns</h2>
        <app-parsing-test-display *ngIf="parsingFormat" [parsingFormat]="parsingFormat" type="noun" ></app-parsing-test-display>
        <h2>Verbs</h2>
        <app-parsing-test-display *ngIf="parsingFormat" [parsingFormat]="parsingFormat" type="verb" ></app-parsing-test-display>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>
    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <p>If you're happy with how the parsings look, click Save Parsing Format” below to save your work.</p>
      <div>
        <button mat-flat-button (click)="accept()">Save Parsing Format</button>
      </div>
    </mat-step>
  </mat-stepper>
    
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onDismiss()">Dismiss</button>
</mat-dialog-actions>