@if( project ) {
<main>
<h2>{{project.title}}</h2>

<p *ngIf="newProject">Feel free to accept the defaults if you're uncertain. You'll be able to edit these values later.</p>

<form [formGroup]="form" (ngSubmit)="save()">

  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
    <mat-tab label="General Settings">
      <mat-form-field>
        <mat-label>Project ID</mat-label>
        <input matInput disabled [value]="project.id" />
      </mat-form-field>
    
      <mat-form-field>
        <mat-label>Project Title</mat-label>
        <input matInput formControlName="project_title" />
      </mat-form-field>
    
      <mat-form-field>
        <mat-label>Project Description</mat-label>
        <textarea matInput placeholder="You may describe your project here..." formControlName="project_description"></textarea>
      </mat-form-field>
    
      <mat-checkbox formControlName="allow_joins">Allow anyone to join (you can still remove them at any time)</mat-checkbox>

      <mat-card>
        <mat-card-header>Settings to help your language display correctly on the website</mat-card-header>
        <mat-card-content>

          <mat-form-field>
            <mat-label>Layout Direction</mat-label>
            <mat-select formControlName="layout_direction">
              <mat-option value="ltr">Left to Right</mat-option>
              <mat-option value="rtl">Right to Left</mat-option>
            </mat-select>
            <mat-hint>“Left to Right” like is like Greek; “Right to Left” is like Hebrew.</mat-hint>
          </mat-form-field>
    
          <mat-form-field>
            <mat-label>Font Families</mat-label>
            <input matInput formControlName="font_families" />
            <mat-hint>Separate multiple values with commas.</mat-hint>
          </mat-form-field>
        
          <mat-form-field>
            <mat-label>Font Size</mat-label>
            <input matInput formControlName="font_size" type="number" />
          </mat-form-field>
        

        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>Settings to help your language display correctly when it is published</mat-card-header>
        <mat-card-content>

          <mat-form-field>
            <mat-label>Select your language (or a similar one)</mat-label>
            <mat-select formControlName="polyglossiaOtherLanguage">
              @for( l of polyglossia_languages; track l ) {
                <mat-option value="{{l}}">{{l}}</mat-option>
              }
            </mat-select>
            <mat-hint>If you don't see your language, pick a language that is close to yours.</mat-hint>
          </mat-form-field>
    
          <mat-form-field>
            <mat-label>Enter the numbers for your language like 0123456789 (no spaces or commas)</mat-label>
            <input matInput formControlName="numerals" type="text" placeholder="0123456789"  [appProjectTextStyle]="project" />
            <mat-error *ngIf="numerals?.invalid && (numerals?.dirty || numerals?.touched)">
              <span *ngIf="numerals?.errors?.['required']">Numerals are required.</span>
              <span *ngIf="numerals?.errors?.['tenDigits']">Please enter exactly 10 digits.</span>
            </mat-error>
          </mat-form-field>
          <div *ngIf="!numerals?.invalid" class="examples">
            <p>Examples:</p>
            <p>123 → {{demoChangeNumerals("123")}}</p>
            <p>405 → {{demoChangeNumerals("405")}}</p>
            <p>978 → {{demoChangeNumerals("978")}}</p>
            <p>629 → {{demoChangeNumerals("629")}}</p>
          </div>
      
          <mat-form-field>
            <mat-label>Show what the chapter header should look like (don't change the “{{ '{chapter}' }}” part—that's where the number will go)”</mat-label>
            <input matInput formControlName="chapterHeader" type="text"  [appProjectTextStyle]="project" />
            <mat-error *ngIf="chapterHeader?.invalid && (chapterHeader?.dirty || chapterHeader?.touched)">
              <span *ngIf="chapterHeader?.errors?.['required']">A chapter header is required.</span>
              <span *ngIf="chapterHeader?.errors?.['needsChapter']">You need to leave “{{ '{chapter}' }}” in... that's where the chapter number will go.</span>
            </mat-error>
          </mat-form-field>
          <div *ngIf="!chapterHeader?.invalid && !numerals?.invalid" class="examples">
            <p>Example:</p>
            <p [appProjectTextStyle]="project">
              {{demoChapterHeader("15")}}
            </p>
          </div>
      
          <mat-form-field>
            <mat-label>Enter a list of footnote markers. Place a space between each marker.</mat-label>
            <input matInput formControlName="footnoteMarkers" type="text"  [appProjectTextStyle]="project" />
          </mat-form-field>
          <div *ngIf="!chapterHeader?.invalid && !numerals?.invalid" class="examples">
            <p>First 32 footnotes:</p>
            <p [appProjectTextStyle]="project">
              {{demoFootnoteMarkers(32)}}
            </p>
          </div>

        </mat-card-content>
      </mat-card>

    </mat-tab>
    <mat-tab label="Canons">
      <mat-card>
        <mat-card-header>Canons</mat-card-header>
        <mat-card-content>
          <mat-selection-list (selectionChange)="canonsChanged($event)">
            @for (c of canonNames; track c) {
              <li>
                <mat-list-option [selected]="project.hasCanon(c)" [value]="c">{{c}}</mat-list-option>
              </li>
            }
          </mat-selection-list>
        </mat-card-content>
      </mat-card>
    
      <mat-card>
        <mat-card-header>Frequency Thresholds</mat-card-header>
        <mat-card-content>
          <p>E.g., “Words that occur fewer than ___ times will be glossed.”</p>
          @for (c of project.canons; track c) {
            <mat-form-field>
              <mat-label>Frequency Threshold for {{c}}</mat-label>
              <input matInput [formControlName]="c" [value]="project.getFrequencyThreshold(c)" type="number" />
            </mat-form-field>
          }
        </mat-card-content>
      </mat-card>
    
      @for (c of project.canons; track c) {
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Book names for {{c}}</mat-panel-title>
          </mat-expansion-panel-header>
          @for (book of canonData(c).books; track book) {
            <mat-form-field>
              <mat-label>{{latinBookName(book)}}</mat-label>
              <input matInput [formControlName]="book" [value]="project.getBookName(book)" />
            </mat-form-field>
          }
        </mat-expansion-panel>
      }
    </mat-tab>
    <mat-tab label="Project Members">
      <div class="centered">
      <table id="members">
        <tr>
          <th>User ID</th>
          <th>Role</th>
          <th>Power User?</th>
        </tr>
        @for (member of project.members; track member) {
          <tr>
            <td>{{project.member(member)?.user_id}}</td>
            <td>
               <mat-select [value]="project.member(member)?.user_role" class="wider" (selectionChange)="setMemberRole(member, $event.value)" *ngIf="member !== user_id">
                  @for (role of projectRoles; track role) {
                    <mat-option [value]="role">{{role}}</mat-option>
                  }
                </mat-select>
                <span *ngIf="member === user_id">{{project.member(member)?.user_role}}</span>
            </td>
            <td>
              <mat-slide-toggle [checked]="project.member(member)?.power_user === 1" (change)="setPowerUser(member,$event.checked)">
                Power User
              </mat-slide-toggle>
          </tr>
        }
      </table>
    </div>
      
      <div id="add-member">
        <button mat-stroked-button color="secondary" (click)="newMember()" type="button">Add a new member...</button>
      </div>

    </mat-tab>

    <mat-tab label="Parsing Formats">
      <p>Each canon of Scripture (e.g., Old Testament, New Testament) needs its own parsing format.</p>
      @for (c of project.canons; track c) {
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>Parsing formats for the {{c}} canon</mat-panel-title>
          </mat-expansion-panel-header>
          @if( project.publicationSettings.getNumberOfConfigurations(c) > 0 ) {
            <mat-list role="list">
            @for(key of project.publicationSettings.getSettingsForCanon(c)?.keys(); track key ) {
              <mat-list-item role="listitem">{{project.publicationSettings.getSettingsForCanon(c)?.get(key)?.id}} 
                <button mat-stroked-button class="margined" (click)="editParsingFormat(c,key)" type="button">Edit</button>
                <button mat-stroked-button class="margined" (click)="removeParsingFormat(c,key)" type="button">Remove</button>
              </mat-list-item>
            }
            </mat-list>
          } @else {
            <p>There are no formats for this canon yet.</p>
          }
          <div id="add-publication-configuration">
            <button mat-button color="secondary" (click)="newParsingFormat(c)" type="button">Add a format...</button>
          </div>
    
        </mat-expansion-panel>
      }
    </mat-tab>

  </mat-tab-group>
  
  




  <div id="buttons">
    <button mat-button (click)="resetData()" type="button" *ngIf="!newProject">Reset</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">Save</button>
  </div>
</form>
</main>
}