<div class="container">
<mat-list>
    <div mat-subheader>Personal Settings</div>
    <mat-list-item>
        <mat-icon matListItemIcon>manage_accounts</mat-icon>
        <div matListItemTitle><a mat-button [routerLink]="['/settings/user', user?.user_id]">{{user?.user_id}}</a></div>
    </mat-list-item>

    <!-- <app-myprojects *ngIf="projects.length > 0"></app-myprojects> -->

</mat-list>
</div>

<div id="new-project">
  <button mat-stroked-button color="secondary" (click)="newProject()">Create a new project...</button>
</div>
