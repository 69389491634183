<ng-container *ngIf="verse; else nodata">
  <!-- The interlinear display -->
  <mat-card>
    <div class="verse" [ngClass]="verse.language">
      <ng-container *ngFor="let word of verse.words">
        <app-glossable-word-display [word]="word" [verse]="verse"></app-glossable-word-display>
      </ng-container>
    </div> 
  </mat-card>

   @if(verse.phraseGlosses.length > 0) {
    <ng-container *ngFor="let key of phraseGlossKeys">
      <mat-card>
        <mat-card-header [ngClass]="verse.language">{{verse.textPortion( key.from, key.to)}}</mat-card-header>
        <mat-card-content>
          <app-phrase-gloss-display [glosses]="phraseGlosses.get(key.asString()) || []" [verse]="verse"></app-phrase-gloss-display>
        </mat-card-content>
      </mat-card>
    </ng-container>
   }


   <div id="addPhraseGloss">
    <button mat-button aria-label="Add phrase gloss" (click)="addPhrasalGloss()">
      Add a phrase gloss...
    </button>
    </div>
</ng-container>
<ng-template #nodata>
  <ng-container *ngIf="verse === null; else verseundefined">
    <div class="error">
      <p>No data came from the server. Check your internet connection and try again. If your internet connection is fine, then the problem is on our end.</p>
    </div>
  </ng-container>
  <ng-template #verseundefined>
    <div class="spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </ng-template>
</ng-template>