import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { AbstractControl, FormControl, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UbsBook, VerseReference } from '@models/VerseReference';
import { MatSelectModule } from '@angular/material/select';
import { ProjectConfiguration } from '@models/ProjectConfiguration';
import { SynchronizerService } from '../synchronizer.service';

@Component({
  selector: 'app-verse-reference-dialog',
  standalone: true,
  imports: [MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatSelectModule,
    ReactiveFormsModule],
  templateUrl: './verse-reference-dialog.component.html',
  styleUrl: './verse-reference-dialog.component.scss'
})
export class VerseReferenceDialogComponent {
  book: UbsBook;
  chapter: number;
  verse: number;
  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private synchronizerService: SynchronizerService,
    public dialogRef: MatDialogRef<VerseReferenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public reference: VerseReference) {
    this.book = reference.ubs_book;
    this.chapter = reference.chapter;
    this.verse = reference.verse;

    this.form = this.formBuilder.group({
      book: new FormControl(this.book),
      chapter: [this.chapter, [Validators.min(1), VerseReferenceDialogComponent.maxValidator(this.maxChapter)]],
      verse: [this.verse, [Validators.min(1), VerseReferenceDialogComponent.maxValidator(this.maxVerse)]]
    });

    this.form.get('book')?.valueChanges.subscribe(value => {
      this.book = value;
      this.bookChanged();
    });

    this.form.get('chapter')?.valueChanges.subscribe(value => {
      if (Number.parseInt(value)) {
        this.chapter = Number.parseInt(value);
      }
    });

    this.form.get('verse')?.valueChanges.subscribe(value => {
      if (Number.parseInt(value)) {
        this.verse = Number.parseInt(value);
      }
    });
  }

  static maxValidator(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value > max) {
        control.setValue(max);
      }
      return null;
    };
  }

  /// convenience
  get projectConfiguation(): ProjectConfiguration | undefined {
    return this.synchronizerService.currentProject;
  }

  get maxChapter(): number {
    return this.synchronizerService.currentCanonData?.lastChapter(this.book) || -1;
  }

  get maxVerse(): number {
    return this.synchronizerService.currentCanonData?.lastVerse(this.book, this.chapter) || -1;
  }

  bookCodes(): UbsBook[] {
    return this.synchronizerService.currentCanonData?.books || [];
  }

  bookName(book: UbsBook): string {
    return this.projectConfiguation?.getBookName(book) || "";
  }

  get formattedVerseReference(): string {
    return this.projectConfiguation?.formatVerseReference(this.reference) || "";
  }

  bookChanged() {
    this.form.get('chapter')?.setValue(1);
    this.form.get('verse')?.setValue(1);

    const chapterControl = this.form.get('chapter');
    const verseControl = this.form.get('verse');
    if (chapterControl == null || verseControl == null) { return; }

    chapterControl.setValidators([Validators.min(1), VerseReferenceDialogComponent.maxValidator(this.maxChapter)]);
    chapterControl.updateValueAndValidity();
    verseControl.setValidators([Validators.min(1), VerseReferenceDialogComponent.maxValidator(this.maxVerse)]);
    verseControl.updateValueAndValidity();
  }

  chapterChanged() {
    this.form.get('verse')?.setValue(1);

    const verseControl = this.form.get('verse');
    if (verseControl == null) { return; }

    verseControl.setValidators([Validators.min(1), VerseReferenceDialogComponent.maxValidator(this.maxVerse)]);
    verseControl.updateValueAndValidity();
  }

  dismiss() {
    this.dialogRef.close();
  }

  go() {
    if (this.synchronizerService.currentCanonData) {
      this.dialogRef.close(new VerseReference(this.book, this.chapter, this.verse, this.synchronizerService.currentCanonData.name));
    }
  }
}
