import { Routes } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { AuthLandingComponent } from './auth-landing/auth-landing.component';
import { JoinProjectComponent } from './join-project/join-project.component';
import { MyprojectsComponent } from './myprojects/myprojects.component';
import { WorkspaceComponent } from './workspace/workspace.component';

export const routes: Routes = [
    { path: 'settings', component: SettingsComponent },
    { path: 'settings/user/:userid', component: UserSettingsComponent },
    { path: 'settings/project/:projectid', component: ProjectSettingsComponent },
    { path: '', component: WelcomeComponent },
    { path: 'login', component: AuthLandingComponent },
    { path: 'join', component: JoinProjectComponent },
    { path: 'myprojects', component: MyprojectsComponent },
    { path: 'workspace/:projectid', component: WorkspaceComponent },
    { path: 'workspace', component: WorkspaceComponent },
];
