import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VerseReference } from '@models/VerseReference';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DatabaseAdapterService } from '../database-adapter.service';
import { VerseReferenceDialogComponent } from '../verse-reference-dialog/verse-reference-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SynchronizerService } from '../synchronizer.service';
import { ProjectConfiguration } from '@models/ProjectConfiguration';
import { VerseProgressIndicatorComponent } from '../verse-progress-indicator/verse-progress-indicator.component';

@Component({
  selector: 'app-bible-navigator',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatIconModule, MatTooltipModule, VerseProgressIndicatorComponent],
  templateUrl: './bible-navigator.component.html',
  styleUrl: './bible-navigator.component.scss'
})
export class BibleNavigatorComponent {
  @Input() reference!: VerseReference;
  @Output() verseUpdated = new EventEmitter();

  constructor(private dbService: DatabaseAdapterService,
    private synchronizerService: SynchronizerService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
  }

  nextVerse() {
    this.reference = this.reference?.nextVerse();
    this.verseUpdated.emit(this.reference);
  }

  previousVerse() {
    this.reference = this.reference?.previousVerse();
    this.verseUpdated.emit(this.reference);
  }

  private get project(): ProjectConfiguration | undefined {
    return this.synchronizerService.currentProject;
  }

  previousVerseWithoutMyVote() {
    if (this.project && this.synchronizerService.user$.value) {
      this.dbService.seekVerse(this.reference, this.synchronizerService.user$.value?.user_id, this.project?.id, this.project.getFrequencyThreshold(this.reference.canon), "before", "me").then((ref: VerseReference) => {
        this.reference = ref;
        this.verseUpdated.emit(this.reference);
      });
    }
  }

  nextVerseWithoutMyVote() {
    if (this.project && this.synchronizerService.user$.value) {
      this.dbService.seekVerse(this.reference, this.synchronizerService.user$.value?.user_id, this.project?.id, this.project.getFrequencyThreshold(this.reference.canon), "after", "me").then((ref: VerseReference) => {
        this.reference = ref;
        this.verseUpdated.emit(this.reference);
      });
    }
  }

  previousVerseWithoutAnyVotes() {
    if (this.project && this.synchronizerService.user$.value) {
      this.dbService.seekVerse(this.reference, this.synchronizerService.user$.value?.user_id, this.project?.id, this.project.getFrequencyThreshold(this.reference.canon), "before", "anyone").then((ref: VerseReference) => {
        this.reference = ref;
        this.verseUpdated.emit(this.reference);
      });
    }
  }

  nextVerseWithoutAnyVotes() {
    if (this.project && this.synchronizerService.user$.value) {
      this.dbService.seekVerse(this.reference, this.synchronizerService.user$.value?.user_id, this.project?.id, this.project.getFrequencyThreshold(this.reference.canon), "after", "anyone").then((ref: VerseReference) => {
        this.reference = ref;
        this.verseUpdated.emit(this.reference);
      });
    }
  }

  verseReferenceDialog() {
    let dialogRef = this.dialog.open(VerseReferenceDialogComponent, { data: this.reference })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reference = result;
        this.verseUpdated.emit(this.reference);
      }
    });
  }

  get formattedVerseReference(): string {
    return this.project?.formatVerseReference(this.reference) || "";
  }


}
