<h2 mat-dialog-title>Add member to project</h2>
<mat-dialog-content>
    <form (ngSubmit)="accept()">
        <app-user-id-autocomplete [exclude]="project.members" (userIdChanged)="updateUserId($event)"></app-user-id-autocomplete>
        <!-- <mat-form-field>
            <mat-label>User ID</mat-label>
            <input matInput [(ngModel)]="userId" name="user_id" cdkFocusInitial [ngModelOptions]="{standalone: true}"/>
        </mat-form-field> -->
        <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select [(ngModel)]="role" name="role" class="wider" [ngModelOptions]="{standalone: true}">
                @for (role of projectRoles; track role) {
                  <mat-option [value]="role">{{role}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-slide-toggle [(ngModel)]="powerUser" name="power_user" [ngModelOptions]="{standalone: true}">
            Make the person a power user
        </mat-slide-toggle>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onDismiss()">Dismiss</button>
  <button mat-flat-button color="primary" (click)="accept()" [disabled]="!valid">Add new member</button>
</mat-dialog-actions>
