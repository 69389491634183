import { Component, inject, model, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MarkdownPipe } from '../markdown.pipe';
import { MatChipListbox, MatChipsModule } from '@angular/material/chips';
import { CommonModule } from '@angular/common';
import { Verse } from '@models/Verse';
import { VerseTextSelectorComponent } from '../verse-text-selector/verse-text-selector.component';
import { Gloss } from '@models/Gloss';
import { MarkdownAnnotation } from '@models/Annotation';
import { PhraseGlossLocation } from '@models/gloss-locations';
import { SynchronizerService } from '../synchronizer.service';

interface PhraseGlossEditorData {
  verse: Verse;
}

@Component({
  selector: 'app-phrase-gloss-editor-dialog',
  standalone: true,
  imports: [CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MarkdownPipe,
    MatChipsModule,
    VerseTextSelectorComponent],
  templateUrl: './phrase-gloss-editor-dialog.component.html',
  styleUrl: './phrase-gloss-editor-dialog.component.scss'
})
export class PhraseGlossEditorDialogComponent {
  readonly dialogRef = inject(MatDialogRef<PhraseGlossEditorDialogComponent>);
  readonly data = inject<PhraseGlossEditorData>(MAT_DIALOG_DATA);
  public verse: Verse;
  @ViewChild('chipList') chipList!: MatChipListbox;
  range: PhraseGlossLocation | null = null;

  readonly gloss = model('');
  readonly markdown = model('');

  constructor(public synchronizerService: SynchronizerService) {
    this.verse = this.data.verse;
  }

  setRange(range: PhraseGlossLocation | null) {
    this.range = range;
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  accept(): void {
    if (this.range !== null) {
      let annotation = new MarkdownAnnotation(this.markdown());
      let location = new PhraseGlossLocation(this.range.from, this.range.to);
      let gloss = new Gloss(annotation, 0, -1, location, 1);
      this.dialogRef.close(gloss);
    } else {
      this.onDismiss();
    }
  }

}
