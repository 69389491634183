import { Pipe, PipeTransform } from '@angular/core';
import { MiniMarkdown } from '@models/MiniMarkdown';

export let converter = new MiniMarkdown();

@Pipe({
  name: 'markdown',
  standalone: true
})
export class MarkdownPipe implements PipeTransform {

  private converter: MiniMarkdown;

  constructor() {
    this.converter = new MiniMarkdown();
  }

  transform(value: string): string {
    if (!value) {
      return '';
    }
    return this.converter.makeHtml(value);
  }

}
