<main>
@if( availableProjects.length > 0 ) {
  <p>Choose a project to join:</p>
    <mat-action-list>
        @for (description of availableProjects; track description.project_id) {
          <mat-list-item (click)="join(description.project_id)">
            <h3 matListItemTitle>{{description.project_title}}</h3>
            <p matListItemLine>
              {{description.project_description}}
            </p>
          </mat-list-item>
        }
      </mat-action-list>
}
@if( availableProjects.length === 0 ) {
    <p>It looks like there aren't any projects to join at this time.</p>
}
</main>