import { Component, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ProjectConfiguration, ProjectId } from '@models/ProjectConfiguration';
import { UserProfile } from '@models/UserProfile';
import { EditorComponent } from '../editor/editor.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Canon } from '@models/VerseReference'
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { SynchronizerService } from '../synchronizer.service';
import { MatListModule } from '@angular/material/list';
import { ProjectIdInputDialogComponent } from '../project-id-input-dialog/project-id-input-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-workspace',
  standalone: true,
  imports: [CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    EditorComponent,
    MatListModule],
  templateUrl: './workspace.component.html',
  styleUrl: './workspace.component.scss'
})
export class WorkspaceComponent implements OnInit {
  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
    public synchronizerService: SynchronizerService,
    private router: Router,
    public dialog: MatDialog) {
    this.form = this.formBuilder.group({
      canon: new FormControl(this.project?.canons),
      project_id: new FormControl(this.user?.projects)
    });

    this.form.get('canon')?.valueChanges.subscribe(value => {
      this.canonChanged();
    });

    this.form.get('project_id')?.valueChanges.subscribe(value => {
      this.projectChanged();
    });
  }

  ngOnInit() {


    this.synchronizerService.position$.subscribe(position => {
      // console.log("Setting position", position);
      if (position) {
        this.form.setValue({
          canon: position.canon,
          project_id: position.project_id
        });
      } else {
        const project = this.user?.projects.next().value as ProjectConfiguration;
        if (project) {
          this.form.setValue({
            canon: project.fallbackCanon(),
            project_id: project.id
          });
        }
      }
    });

  }

  get project(): ProjectConfiguration | undefined {
    return this.synchronizerService.currentProject;
  }

  get user(): UserProfile | undefined {
    return this.synchronizerService.user$.value;
  }

  canonChanged() {
    this.synchronizerService.setCanon(this.form.get('canon')?.value as Canon);
    // if (this.user?.currentPosition) {
    //   this.user.currentPosition.canon = this.form.get('canon')?.value;
    //   this.dbService.forceVerseUpdate(this.user.currentPosition.ref);
    // }
  }

  projectChanged() {
    let currentCanon = this.form.get('canon')?.value as Canon;
    let pid = this.form.get('project_id')?.value as ProjectId;
    let project = this.user?.project(pid);
    if (project && this.user) {
      if (this.synchronizerService.currentPosition === undefined) {
        this.synchronizerService.setToFallbackPosition();
      }
      let canon: Canon;
      if (this.user?.project(pid)?.hasCanon(currentCanon)) {
        canon = currentCanon;
      } else {
        canon = project.fallbackCanon();
      }
      this.synchronizerService.setProject(pid, canon);
      this.form.controls['canon'].setValue(canon);
      /// TODO find out why this was here. We may need some forcing provision in sychronizer.updateVerse (?)      
      // this.dbService.forceVerseUpdate(this.synchronizerService.currentPosition.ref);
    } else {
      console.error(`No project available for ${pid}. Setting to fallback position.`);
      this.synchronizerService.setToFallbackPosition();
    }
  }

  newProject(): void {
    const dialogRef = this.dialog.open(ProjectIdInputDialogComponent, {
      width: '400px',
      data: { text: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/settings/project/' + result]);
      }
    });
  }

  navigateToJoin() {
    this.router.navigate(['/join']);
  }

  openDocumentation() {
    window.open('https://docs.openreadersbibles.org', '_blank');
  }

}
