<table mat-table [dataSource]="dataSource">

<ng-container matColumnDef="raw">
    <th mat-header-cell *matHeaderCellDef> Parsing </th>
    <td mat-cell *matCellDef="let row"> {{rawFormat(row)}} </td>
</ng-container>
      
<ng-container matColumnDef="result">
    <th mat-header-cell *matHeaderCellDef> Parsing </th>
    <td mat-cell *matCellDef="let row"> {{parsing(row)}} </td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
<tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

</table>


<mat-paginator [pageSizeOptions]="[5, 10, 20]"
showFirstLastButtons
aria-label="Select page of parsing buttons" #paginator>
</mat-paginator>