import { Component, Input } from '@angular/core';
import { Word } from '@models/Word';
import { GlossSelectorComponent } from "../gloss-selector/gloss-selector.component";
import { DatabaseAdapterService } from '../database-adapter.service';
import { CommonModule } from '@angular/common';
import { Verse } from '@models/Verse';
import { ParsingSummaryComponent } from '../parsing-summary/parsing-summary.component';
import { MatDialog } from '@angular/material/dialog';
import { SynchronizerService } from '../synchronizer.service';

@Component({
  selector: 'app-glossable-word-display',
  standalone: true,
  templateUrl: './glossable-word-display.component.html',
  styleUrl: './glossable-word-display.component.scss',
  imports: [CommonModule, GlossSelectorComponent]
})
export class WordDisplayComponent {
  @Input() word!: Word;
  @Input() verse!: Verse;

  constructor(public dbService: DatabaseAdapterService,
    private synchronizerService: SynchronizerService,
    public dialog: MatDialog
  ) {
  }

  get frequencyThreshold(): number {
    if (!this.word) return 0;
    return this.synchronizerService.currentProject?.getFrequencyThreshold(this.word.reference.canon) || 50;
  }

  layoutDirectionLtr(): boolean {
    return this.synchronizerService.currentProject?.layout_direction === "ltr";
  }

  showParsing() {
    const dialogRef = this.dialog.open(ParsingSummaryComponent, {
      width: '400px',
      data: { word: this.word }
    });
  }

}
