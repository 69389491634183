import { Injectable } from '@angular/core';
import { Word } from '@models/Word';
import { Canon, VerseReference } from '@models/VerseReference';
import { Verse } from '@models/Verse';
import { GetHebrewVerseResponse } from '@models/HebrewWordRow';
import { ProjectConfiguration, ProjectDescription, ProjectId } from '@models/ProjectConfiguration';
import { HebrewWordElement } from '@models/HebrewWordElement';
import { UserId, UserProfile } from '@models/UserProfile';
import { Annotation, annotationFromObject } from '@models/Annotation';
import { PhraseGlossLocation } from '@models/gloss-locations';
import { Gloss } from '@models/Gloss';
import { ProjectPackage, ServerResponse, UpdateVerseData } from '@models/database-input-output';
import { CognitoService } from './cognito.service';
import { GetNTVerseResponse } from '@models/GreekWordRow';
import { GreekWordElement } from "@models/GreekWordElement";
import { environment } from 'src/environments/environment';
import { ReturnedWrappedBody, SavedPostRequest, WrappedBody } from "@models/SavedPostRequest";


@Injectable({
  providedIn: 'root'
})
export class DatabaseAdapterService {
  private serverUrl: string = environment.cognito.api_server_url;

  constructor(private cognitoService: CognitoService) {
    // Run checkForSavedRequests every 10 seconds
    setInterval(() => this.checkForSavedRequests(), 10000);
  }

  loadVerseFromAPI(ref: VerseReference, userId: UserId, projectId: ProjectId): Promise<Verse | null> {
    // console.log("loadVerseFromAPI: " + ref.toString());
    switch (ref.canon) {
      case 'OT':
        return this.getHebrewVerse(ref, userId, projectId);
      case 'NT':
        return this.getNTVerse(ref, userId, projectId);
      case 'LXX':
        console.log("LXX not implemented yet");
        return Promise.reject("LXX not implemented yet");
    }
  }

  private getVerseUrl(ref: VerseReference, userId: UserId, projectId: ProjectId): string {
    return `${this.serverUrl}/getverse/${userId}/${projectId}/${ref.toString()}`;
  }

  getHebrewVerse(ref: VerseReference, userId: UserId, projectId: ProjectId): Promise<Verse | null> {
    // console.log("getHebrewVerse: " + ref.toString());
    const url = this.getVerseUrl(ref, userId, projectId);
    return this.getRequest(url)
      .then((returnedObject: any) => {
        if (returnedObject.status) {
          console.error("Error: " + returnedObject.status);
          console.error(url);
          return null;
        }
        let data = returnedObject as GetHebrewVerseResponse;
        // console.log(data);

        if (data !== null && data !== undefined) {
          let words = new Array<Word>();
          words.push(new Word(ref));
          for (const word of data.words) {
            let suggestions: Annotation[] = [];
            let matchingThisLexicalId = data.suggestions.filter(suggestion => suggestion.lex_id === word.lex_id);
            if (matchingThisLexicalId.length > 0) {
              suggestions = matchingThisLexicalId[0].suggestions
                .map(s => annotationFromObject(s))
                .filter(s => s !== undefined) as Annotation[];
            }

            words[words.length - 1].addElement(new HebrewWordElement(word, suggestions));
            if (word.trailer_utf8.length > 0) {
              words.push(new Word(ref));
            }
          }
          if (words[words.length - 1].elementCount === 0) {
            words.pop();
          }
          let phraseGlosses = data.phrase_glosses.map(row => Gloss.fromPhraseGlossRow(row, new PhraseGlossLocation(row.from_word_id, row.to_word_id), row.phrase_gloss_id === row.myVote ? 1 : 0));

          return new Verse(ref, words, 'hebrew', phraseGlosses);
        } else {
          console.log("No data found for verse: " + ref.toString() + " in project: " + projectId + ".");
          return null;
        }
      });
  }

  getNTVerse(ref: VerseReference, userId: UserId, projectId: ProjectId): Promise<Verse | null> {
    // console.log("getNTVerse: " + ref.toString());
    const url = this.getVerseUrl(ref, userId, projectId);
    return this.getRequest(url)
      .then((returnedObject: any) => {
        if (returnedObject.status) {
          console.error("Error: " + returnedObject.status);
          console.error(url);
          return null;
        }
        let data = returnedObject as GetNTVerseResponse;
        // console.log(data);
        if (data !== null && data !== undefined) {
          let words = new Array<Word>();

          for (const word of data.words) {
            words.push(new Word(ref));
            let suggestions: Annotation[] = [];
            let matchingThisLexicalId = data.suggestions.filter((suggestion: any) => suggestion.lex_id === word.lex_id);
            if (matchingThisLexicalId.length > 0) {
              suggestions = matchingThisLexicalId[0].suggestions
                .map((s: any) => annotationFromObject(s))
                .filter((s: any) => s !== undefined) as Annotation[];
            }

            words[words.length - 1].addElement(new GreekWordElement(word, suggestions));
          }
          let phraseGlosses = data.phrase_glosses.map((row: any) => Gloss.fromPhraseGlossRow(row, new PhraseGlossLocation(row.from_word_id, row.to_word_id), row.phrase_gloss_id === row.myVote ? 1 : 0));
          return new Verse(ref, words, 'greek', phraseGlosses);
        } else {
          console.log("No data found for verse: " + ref.toString() + " in project: " + projectId + ".");
          return null;
        }
      });
  }

  updateVerse(canon_name: Canon, verse: Verse, userId: UserId, projectId: ProjectId): Promise<ServerResponse> {
    // console.log("Updating verse: " + verse.reference.toString());

    const url = `${this.serverUrl}/updateverse/${userId}/${projectId}/${verse.reference.toString()}`;
    let glossUpdates = verse.changedGlosses().map(gloss => gloss.toGlossSendObject());
    let phraseGlossUpdates = verse.changedPhraseGlosses().map(gloss => gloss.toGlossSendObject());

    let updateObject: UpdateVerseData = {
      canon_name: canon_name,
      word_gloss_updates: glossUpdates,
      phrase_gloss_updates: phraseGlossUpdates
    };

    verse.markGlossesAsUnchanged();
    return this.safePostRequest(url, updateObject);
  }

  /// TODO this is better off returning a complete Verse rather than just a VerseReference
  seekVerse(startingPosition: VerseReference, userId: UserId, projectId: ProjectId, frequencyThreshold: number, direction: "before" | "after", exclusivity: "me" | "anyone"): Promise<VerseReference> {
    const url = `${this.serverUrl}/seekVerse/${userId}/${projectId}/${frequencyThreshold}/${startingPosition.toString()}/${direction}/${exclusivity}`;
    return fetch(url)
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        return VerseReference.fromString(data.Reference) || startingPosition.canonData.fallbackVerseReference();
      })
      .catch(error => {
        console.error('Error:', error);
        console.error(url);
        return startingPosition.canonData.fallbackVerseReference();
      });
  }

  saveProjectConfiguration(project: ProjectConfiguration, isNewProject: boolean): Promise<ServerResponse> {
    const url = `${this.serverUrl}/updateproject`;
    // console.log(project.toObject());
    let projectPackage: ProjectPackage = { project: project.toObject(), new_project: isNewProject };
    return this.safePostRequest(url, projectPackage)
      .then((data: any) => {
        return data;
      })
      .catch((error) => {
        console.error('Error:', error);
        console.error(url);
        return Promise.reject(error);
      });
  }

  getUserData(username: string): Promise<UserProfile> {
    const url = `${this.serverUrl}/user/${username}`;
    return this.getRequest(url)
      .then((data: any) => {
        return new UserProfile(data)
      })
      .catch((error) => {
        console.error('Error:', error);
        console.error(url);
        return Promise.reject(error);
      });
  }

  getProjectIdExists(projectId: string): Promise<{ project_id: string, exists: boolean | undefined }> {
    const url = `${this.serverUrl}/projectExists/${projectId}`;;
    return this.getRequest(url)
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        console.error('Error:', error);
        return { project_id: projectId, exists: undefined };
      });
  }

  updateUser(user: UserProfile): Promise<ServerResponse> {
    const url = `${this.serverUrl}/updateuser`;
    let postData = user.toObject();
    return this.safePostRequest(url, postData)
      .then((data: any) => {
        return data;
      })
      .catch((error) => {
        console.error('Error:', error);
        console.error(url);
        return Promise.reject(error);
      });
  }

  async safePostRequest(url: string, body: any = {}): Promise<ServerResponse> {
    /// save the request to local storage in case it fails
    const savedRequest = await SavedPostRequest.create(url, body);
    localStorage.setItem(savedRequest.hash, savedRequest.toString());
    const wb: WrappedBody = { body: body, hash: savedRequest.hash };
    return this.postRequest(url, wb)
      .then((data: ReturnedWrappedBody) => {
        /// remove the saved request from local storage
        localStorage.removeItem(savedRequest.hash);
        return data.body;
      });
  }

  private async checkForSavedRequests(): Promise<void> {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key && key.startsWith("transaction:")) {
        let savedRequest = await SavedPostRequest.fromString(localStorage.getItem(key) || "");
        if (savedRequest) {
          this.postRequest(savedRequest.url, savedRequest)
            .then((data: ReturnedWrappedBody) => {
              localStorage.removeItem(key);
            });
        }
      }
    }
  }

  private postRequest(url: string, body: any = {}): Promise<ReturnedWrappedBody> {
    let id_token = this.cognitoService.accessToken$.value?.idToken || "";
    if (id_token.length > 0) {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': id_token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((response: Response) => response.json())
        .then((data: ReturnedWrappedBody) => {
          return data;
        })
        .catch(error => {
          console.error('Error:', error);
          console.log(body);
          return Promise.reject({ status: "internal_failure" });
        });
    } else {
      console.error("Access token was undefined or had zero length.");
      return Promise.reject({ status: "authentication_failure" });
    }
  }

  private getRequest(url: string): Promise<ServerResponse> {
    let id_token = this.cognitoService.accessToken$.value?.idToken || "";
    if (id_token.length > 0) {
      return fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': id_token,
          'Content-Type': 'application/json',
        },
      })
        .then((response: Response) => response.json())
        .then((data: WrappedBody) => {
          if (data === null || data === undefined) {
            console.error("Data was null or undefined.");
            console.error(url);
            return Promise.reject({ status: "internal_failure" });
          }
          /// the hash value should be ignored for GET requests
          return data.body;
        })
        .catch(error => {
          console.error('Error:', error);
          console.log(url);
          return Promise.reject({ status: "internal_failure" });
        });
    } else {
      console.error("Access token was undefined or had zero length.");
      return Promise.reject({ status: "authentication_failure" });
    }
  }

  getUserIds(): Promise<string[]> {
    const url = `${this.serverUrl}/userids`;
    return this.getRequest(url)
      .then((data: any) => {
        return data;
      })
      .catch((error) => {
        console.error('Error:', error);
        return Promise.reject(error);
      });
  }

  getProjectDescriptions(): Promise<ProjectDescription[]> {
    const url = `${this.serverUrl}/projectdescriptions`;
    return this.getRequest(url)
      .then((data: any) => {
        return data;
      })
      .catch((error) => {
        console.error('Error:', error);
        return Promise.reject(error);
      });
  }

  joinProject(project_id: ProjectId): Promise<ServerResponse> {
    const url = `${this.serverUrl}/joinproject/${project_id}`;
    return this.safePostRequest(url, {})
      .then((data: any) => {
        return data;
      })
      .catch((error) => {
        console.error('Error:', error);
        console.error('Error:', url);
        return Promise.reject(error);
      });
  }

}

