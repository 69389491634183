import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ParsingFormat } from '@models/parsing-formats/ParsingFormat';
import { nonNumeralConverter } from '@models/parsing-formats/farsiNumeralConverter';
import { NT_NOUNS, NT_VERBS, OT_NOUNS, OT_VERBS } from '@models/parsing-formats/ParsingFormatCombinations';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { OTVerboseEnglish } from '@models/parsing-formats/OTVerboseParsingFormatStrings';
import { NTVerboseParsingFormat } from '@models/parsing-formats/NTVerboseParsingFormat';
import { NTVerboseEnglish } from '@models/parsing-formats/NTVerboseParsingFormatStrings';
import { OTVerboseParsingFormat } from '@models/parsing-formats/OTVerboseParsingFormat';

@Component({
  selector: 'app-parsing-test-display',
  standalone: true,
  imports: [MatTableModule, MatPaginatorModule],
  templateUrl: './parsing-test-display.component.html',
  styleUrl: './parsing-test-display.component.scss'
})
export class ParsingTestDisplayComponent implements OnInit, AfterViewInit {
  @Input() parsingFormat!: ParsingFormat;
  @Input() type!: 'noun' | 'verb';
  rows: any[] = [];
  columnsToDisplay = ['raw', 'result'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>(this.rows);
  rawParsingFormat!: ParsingFormat;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    const canon = this.parsingFormat.canon;
    if (canon === "NT") {
      this.rawParsingFormat = new NTVerboseParsingFormat('dummy', 'nt-verbose', nonNumeralConverter, NTVerboseEnglish);
    } else if (canon === "OT") {
      this.rawParsingFormat = new OTVerboseParsingFormat('dummy', 'ot-verbose', nonNumeralConverter, OTVerboseEnglish);
    }

    if (this.type === 'noun') {
      this.rows = this.nounCombinations;
      this.dataSource = new MatTableDataSource(this.rows);
    } else if (this.type === 'verb') {
      this.rows = this.verbCombinations;
      this.dataSource = new MatTableDataSource(this.rows);
    } else {
      throw new Error("Unknown type: " + this.type);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  get verbCombinations(): any[] {
    const canon = this.parsingFormat.canon;
    if (canon == "NT") {
      return NT_VERBS;
    } else if (canon == "OT") {
      return OT_VERBS;
    } else {
      throw new Error("Unknown canon: " + canon);
    }
  }


  get nounCombinations(): any[] {
    const canon = this.parsingFormat.canon;
    if (canon == "NT") {
      return NT_NOUNS;
    } else if (canon == "OT") {
      return OT_NOUNS;
    } else {
      throw new Error("Unknown canon: " + canon);
    }
  }

  parsing(row: any): string {
    if (this.type === 'noun') {
      return this.parsingFormat.nounParsingString(row);
    } else if (this.type === 'verb') {
      return this.parsingFormat.verbParsingString(row);
    } else {
      throw new Error("Unknown type: " + this.type);
    }
  }

  rawFormat(row: any): string {
    if (this.type === 'noun') {
      return this.rawParsingFormat.nounParsingString(row);
    } else if (this.type === 'verb') {
      return this.rawParsingFormat.verbParsingString(row);
    } else {
      throw new Error("Unknown type: " + this.type);
    }
  }


}