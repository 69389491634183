import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserId } from '@models/UserProfile';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatabaseAdapterService } from '../database-adapter.service';

@Component({
  selector: 'app-user-id-autocomplete',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule
  ],
  templateUrl: './user-id-autocomplete.component.html',
  styleUrl: './user-id-autocomplete.component.scss',
})
export class UserIdAutocompleteComponent {
  @ViewChild('input') input: ElementRef<HTMLInputElement> | undefined;
  myControl = new FormControl('');
  @Input() exclude: UserId[] = [];
  @Output() userIdChanged = new EventEmitter<UserId>();
  userId: UserId = "";
  options: UserId[] = [];
  filteredOptions: string[] = [];

  constructor(private dbService: DatabaseAdapterService) {
    this.dbService.getUserIds()
      .then((value: string[]) => {
        this.options = value.filter((u) => this.exclude.indexOf(u) == -1);
        this.filteredOptions = this.options.slice();
      });
  }

  optionSelected(event: any) {
    this.userIdChanged.emit(event.option.value);
  }

  filter(): void {
    if (this.input) {
      const filterValue = this.input.nativeElement.value.toLowerCase();
      this.filteredOptions = this.options.filter(o => o.toLowerCase().includes(filterValue));
    }
  }

}
