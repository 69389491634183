import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Word } from '@models/Word';

@Component({
  selector: 'app-parsing-summary',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  templateUrl: './parsing-summary.component.html',
  styleUrl: './parsing-summary.component.scss'
})
export class ParsingSummaryComponent {
  readonly dialogRef = inject(MatDialogRef<ParsingSummaryComponent>);
  readonly data = inject<ParsingSummaryComponent>(MAT_DIALOG_DATA);
  word: Word;
  parsingSummary: Map<string, string>[];

  constructor() {
    this.word = this.data.word;
    this.parsingSummary = this.word.parsingSummary();
  }

  onDismiss(): void {
    this.dialogRef.close();
  }
}
