import { Component, inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProjectRole, PROJECT_ROLES, ProjectRoleRow, ProjectConfiguration } from '@models/ProjectConfiguration';
import { UserId } from '@models/UserProfile';
import { UserIdAutocompleteComponent } from '../user-id-autocomplete/user-id-autocomplete.component';

@Component({
  selector: 'app-edit-member-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatSlideToggleModule,
    MatSelectModule,
    UserIdAutocompleteComponent
  ],
  templateUrl: './edit-member-dialog.component.html',
  styleUrl: './edit-member-dialog.component.scss'
})
export class EditMemberDialogComponent {
  readonly dialogRef = inject(MatDialogRef<EditMemberDialogComponent>);
  readonly data = inject<EditMemberDialogComponent>(MAT_DIALOG_DATA);
  userId: UserId = '';
  role: ProjectRole = 'member';
  powerUser: 0 | 1 = 0;
  project: ProjectConfiguration;

  constructor() {
    this.project = this.data.project;
  }

  get projectRoles(): ProjectRole[] {
    return PROJECT_ROLES;
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  updateUserId(event: string) {
    this.userId = event;
  }

  get valid(): boolean {
    return this.userId.length > 0;
  }

  accept(): void {
    let result: ProjectRoleRow = {
      user_id: this.userId,
      user_role: this.role,
      power_user: this.powerUser
    };
    this.dialogRef.close(result);
  }
}
