import { Component, Input, OnInit } from '@angular/core';
import { Gloss } from '@models/Gloss';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GlossContainer } from '@models/WordElement';
import { SynchronizerService } from '../synchronizer.service';
import { ProjectTextStyleDirective } from '../project-text-style.directive';


@Component({
  selector: 'app-gloss-selector-line',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, ProjectTextStyleDirective],
  templateUrl: './gloss-selector-line.component.html',
  styleUrl: './gloss-selector-line.component.scss'
})
export class GlossSelectorLineComponent {
  @Input() gloss!: Gloss;
  @Input() container!: GlossContainer;

  constructor(public synchronizerService: SynchronizerService) {
  }

  toggleVote() {
    this.gloss.toggleGloss();
    this.container.setVote(this.gloss);
    // console.log("Toggling vote", this.gloss.text, this.gloss.isMyVote);
    this.container = this.container.copyOf();
  }

  layoutDirectionLtr(): boolean {
    return this.synchronizerService.currentProject?.layout_direction === "ltr";
  }

}
