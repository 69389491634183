@if( user) {
@if( user.numberOfProjects > 0) {
<!-- The navigation bar showing the Proejct combo on the left, canon on the right -->
<form [formGroup]="form">
  <mat-toolbar color="secondary">
      <div id="row">
      <div>
      <mat-select formControlName="project_id">
        @for (project of user.projects; track project.id) {
            <mat-option [value]="project.id">{{project.title}}</mat-option>
        }
      </mat-select>
    </div>
    <div class="flex-spacer"></div>
    <div>
      <mat-select formControlName="canon">
        @for (canon of project?.canons; track canon) {
            <mat-option [value]="canon">{{canon}}</mat-option>
        }
        </mat-select>
      </div>
    </div>
  </mat-toolbar>
</form>
<!-- The editor -->
<main>
  <ng-container *ngIf="synchronizerService.currentPosition">
    <app-editor [reference]="synchronizerService.currentPosition.ref"></app-editor>
  </ng-container>
</main>
}
@else {
  <main class="noProjects">
  <p>Welcome to Open Reader's Bibles! It looks like you haven't started or joined any projects yet. Here are some options to get you started.</p>

  <mat-nav-list>
    <mat-list-item (click)="openDocumentation()">
      <mat-icon matListItemIcon>menu_book</mat-icon>
      <span matListItemTitle>Read the documentation...</span>
    </mat-list-item>
    <mat-list-item (click)="newProject()">
        <mat-icon matListItemIcon>add</mat-icon>
        <span matListItemTitle>Start a new project...</span>
    </mat-list-item>
    <!-- <mat-list-item routerLink='/join'> -->
      <mat-list-item (click)="navigateToJoin()">
        <mat-icon matListItemIcon>group_add</mat-icon>
      <span matListItemTitle>Join an existing project...</span>
    </mat-list-item>
  </mat-nav-list>
  
  <!-- (click)="newProject()" -->
</main>
}
}
