<div mat-subheader>My Projects</div>
<table>
@for (project of projects; track project) {
    @if( project.userRole( user?.user_id || '') === 'admin' ) {
      <tr>
        <td class="title">{{project.title}}</td>
        <td><a mat-button [routerLink]="['/workspace', project.id]" (click)="changeProject(project.id)">Workspace  <mat-icon>build</mat-icon></a></td>
        <td><a mat-button [routerLink]="['/settings/project', project.id]">Project Settings  <mat-icon>edit</mat-icon></a></td>
      </tr>
  }
    @else {
      <tr>
        <td class="title" colspan="3">{{project.title}}</td>
      </tr>
    }
}
</table>
@if( projects.length === 0 ) {
    <p>No projects found.</p>
}