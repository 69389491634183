import { Component } from '@angular/core';
import { Verse } from '@models/Verse';
import { SynchronizerService } from '../synchronizer.service';

@Component({
  selector: 'app-verse-progress-indicator',
  standalone: true,
  imports: [],
  templateUrl: './verse-progress-indicator.component.html',
  styleUrl: './verse-progress-indicator.component.scss'
})
export class VerseProgressIndicatorComponent {

  constructor(private synchronizerService: SynchronizerService) {
  }

  get verse(): Verse | undefined | null {
    return this.synchronizerService.verse$.value;
  }

  get remainingWords(): number {
    const project = this.synchronizerService.currentProject;
    const verse = this.synchronizerService.verse$.value;
    if (project && verse) {
      return verse.wordsRemainingToGloss(project.getFrequencyThreshold(verse.reference.canon));
    } else {
      return -1;
    }
  }

}
