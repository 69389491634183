export const environment = {
    production: false,
    cognito: {
        auth_url: 'https://auth.openreadersbibles.org',
        client_id: '4ph7hthgr71rs0jiq6glf052g0',
        redirect_uri: 'https://dev.openreadersbibles.org/login',
        client_secret: 'n1nbeb2uonujh3h9122nglr0n7falcb2skue8t421g6ku312nh7',
        api_server_url: "https://92cyez6kij.execute-api.us-east-1.amazonaws.com/dev"
    }
};
