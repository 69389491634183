import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ProjectConfiguration } from '@models/ProjectConfiguration';

@Directive({
  selector: '[appProjectTextStyle]',
  standalone: true
})
export class ProjectTextStyleDirective implements OnChanges {
  @Input('appProjectTextStyle') project: ProjectConfiguration | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['project'] && this.project) {
      this.renderer.setStyle(this.el.nativeElement, 'font-family', this.project.font_families);
      this.renderer.setStyle(this.el.nativeElement, 'font-size', this.project.font_size + "pt");
      this.renderer.setStyle(this.el.nativeElement, 'direction', this.project.layout_direction);
    }
  }

}
