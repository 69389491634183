<h2 mat-dialog-title>Select Verse</h2>
<mat-dialog-content>
    <form [formGroup]="form" (keydown.enter)="go()">
        <mat-form-field>
            <mat-label>Book</mat-label>
            <mat-select formControlName="book" (valueChange)="bookChanged()">
            @for (book of bookCodes(); track book) {
                <mat-option [value]="book">{{bookName(book)}}</mat-option>
            }
            </mat-select>
        </mat-form-field>
        <mat-form-field class="narrow">
            <mat-label>Chapter</mat-label>
            <input matInput [value]="chapter" type="number" formControlName="chapter" min="1" [max]="maxChapter" (change)="chapterChanged()">
        </mat-form-field>
        <mat-form-field class="narrow">
            <mat-label>Verse</mat-label>
            <input matInput [value]="verse" type="number" formControlName="verse" min="1" [max]="maxVerse" (change)="this.form.updateValueAndValidity()">
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="dismiss()">Dismiss</button>
  <button mat-flat-button color="primary" (click)="go()" cdkFocusInitial>Go</button>
</mat-dialog-actions>
