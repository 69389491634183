import { Pipe, PipeTransform } from '@angular/core';
import { Gloss } from '@models/Gloss';

@Pipe({
  name: 'sortByVotes',
  standalone: true
})
export class SortByVotesPipe implements PipeTransform {

  transform(value: Gloss[]): Gloss[] {
    // console.log("Sorting by votes. First is:", value.sort((a, b) => b.votes - a.votes)[0].text);
    /// sort by descending votes
    return value.sort((a, b) => b.votes - a.votes);
  }

}
