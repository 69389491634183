export const POLYGLOSSIA_LANGUAGES = [
    "afrikaans",
    "albanian",
    "amharic",
    "arabic",
    "armenian",
    "asturian",
    "basque",
    "belarusian",
    "bengali",
    "bosnian",
    "breton",
    "bulgarian",
    "catalan",
    "chinese",
    "coptic",
    "croatian",
    "czech",
    "danish",
    "divehi",
    "dutch",
    "english",
    "esperanto",
    "estonian",
    "finnish",
    "french",
    "friulian",
    "gaelic",
    "galician",
    "georgian",
    "german",
    "greek",
    "hebrew",
    "hindi",
    "hungarian",
    "icelandic",
    "interlingua",
    "italian",
    "japanese",
    "kannada",
    "khmer",
    "korean",
    "kurdish",
    "lao",
    "latin",
    "latvian",
    "lithuanian",
    "macedonian",
    "malay",
    "malayalam",
    "marathi",
    "mongolian",
    "nko",
    "norwegian",
    "occitan",
    "odia",
    "persian",
    "piedmontese",
    "polish",
    "portuguese",
    "punjabi",
    "romanian",
    "romansh",
    "russian",
    "sami",
    "sanskrit",
    "serbian",
    "slovak",
    "slovenian",
    "sorbian",
    "spanish",
    "swedish",
    "syriac",
    "tamil",
    "telugu",
    "thai",
    "tibetan",
    "turkish",
    "turkmen",
    "ukrainian",
    "urdu",
    "uyghur",
    "vietnamese",
    "welsh"
];