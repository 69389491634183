import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectConfiguration, ProjectId } from '@models/ProjectConfiguration';
import { UserProfile } from '@models/UserProfile';
import { SynchronizerService } from '../synchronizer.service';
import { MatListModule } from '@angular/material/list';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-myprojects',
  standalone: true,
  imports: [MatListModule, RouterModule, MatIconModule, MatButtonModule],
  templateUrl: './myprojects.component.html',
  styleUrl: './myprojects.component.scss'
})
export class MyprojectsComponent {

  constructor(
    private router: Router,
    private synchronizerService: SynchronizerService,
    public dialog: MatDialog) {
  }

  get user(): UserProfile | undefined {
    return this.synchronizerService.user$.value;
  }

  get projects(): ProjectConfiguration[] {
    return Array.from(this.user?.projects || []).sort((a: ProjectConfiguration, b: ProjectConfiguration) => b.title.localeCompare(a.title)).reverse();
  }

  changeProject(pid: ProjectId) {
    let project = this.user?.project(pid);
    if (project) {
      this.synchronizerService.setProject(pid, project.fallbackCanon());
    }
  }

}
