import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule
import { CommonModule } from '@angular/common';
import { DatabaseAdapterService } from '../database-adapter.service';

@Component({
  selector: 'app-project-id-input-dialog',
  standalone: true,
  imports: [MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule],
  templateUrl: './project-id-input-dialog.component.html',
  styleUrl: './project-id-input-dialog.component.scss'
})
export class ProjectIdInputDialogComponent implements OnInit {
  form: FormGroup;
  projectIdAlreadyExists: boolean | undefined = false;

  constructor(
    private dbService: DatabaseAdapterService,
    public dialogRef: MatDialogRef<ProjectIdInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text: string },
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      text: [data.text, [Validators.required, ProjectIdInputDialogComponent.idValidator()]]
    });
  }

  ngOnInit(): void {
    this.form.get('text')?.valueChanges.subscribe(value => {
      this.checkIfExists(value);
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.text);
    }
  }

  checkIfExists(id: string): void {
    this.dbService.getProjectIdExists(id)
      .then(returnValue => {
        /// the control may have a different value now
        /// so check before displaying a message
        if (this.form.get('text')?.value === returnValue.project_id) {
          this.projectIdAlreadyExists = returnValue.exists;
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  static idValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validStringPattern = /^[a-zA-Z0-9-]*$/;
      const isValid = validStringPattern.test(control.value);
      return isValid ? null : { invalidString: { value: control.value } };
    };
  }
}
