<h1 mat-dialog-title>Enter a new project ID</h1>
<div mat-dialog-content>  
  <form [formGroup]="form" (keydown.enter)="onSubmit()">
    <p>Great! The first step is to come up with a computer-friendly label for your project. Choose something like “Kiswahili-New-Testament” or “Russian-OT”.</p>
    <mat-form-field>
      <mat-label>Project ID</mat-label>
      <input matInput formControlName="text" spellcheck="false">
    </mat-form-field>
    <p class="error" *ngIf="projectIdAlreadyExists">This project ID is already in use.</p>
    <p class="error" *ngIf="projectIdAlreadyExists === undefined">There was an error checking the ID.</p>
    <p>A project ID can be uppercase or lowercase English letters, Arabic numbers, and hyphens. (E.g., “Chinese-Edition-2024”)</p>
  </form>
</div>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">Dismiss</button>
  <button mat-flat-button (click)="onSubmit()" [disabled]="form.invalid || projectIdAlreadyExists">Create Project</button>
</mat-dialog-actions>