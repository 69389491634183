import { Component } from '@angular/core';
import { WorkspaceComponent } from '../workspace/workspace.component';
import { CommonModule } from '@angular/common';
import { UserProfile } from '@models/UserProfile';
import { CognitoService } from '../cognito.service';
import { FeedbackMessageComponent } from '../feedback-message/feedback-message.component';
import { SynchronizerService } from '../synchronizer.service';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [CommonModule,
    WorkspaceComponent,
    FeedbackMessageComponent],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent {
  constructor(
    public synchronizerService: SynchronizerService,
    public cognitoService: CognitoService
  ) {
    // console.log(this.synchronizerService.user$.value);

  }

  get user(): UserProfile | undefined {
    return this.synchronizerService.user$.value;
  }

}
