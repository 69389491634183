import { Component, inject, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Annotation, AnnotationType, MarkdownAnnotation, WordAnnotation, WordPlusMarkdownAnnotation } from '@models/Annotation';
import { MarkdownPipe } from '../markdown.pipe'; // Adjust the path as necessary
import { SynchronizerService } from '../synchronizer.service';
import { ProjectTextStyleDirective } from '../project-text-style.directive';

export interface NewGlossDialogData {
  type: AnnotationType;
}

@Component({
  selector: 'app-new-gloss-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MarkdownPipe,
    ProjectTextStyleDirective
  ],
  templateUrl: './new-gloss-dialog.component.html',
  styleUrl: './new-gloss-dialog.component.scss'
})
export class NewGlossDialogComponent {

  readonly dialogRef = inject(MatDialogRef<NewGlossDialogComponent>);
  readonly data = inject<NewGlossDialogData>(MAT_DIALOG_DATA);

  readonly gloss = model('');
  readonly markdown = model('');

  constructor(public synchronizerService: SynchronizerService) {

  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  accept(): void {
    let result: Annotation;
    switch (this.data.type) {
      case 'word':
        result = new WordAnnotation(this.gloss());
        break;
      case 'markdown':
        result = new MarkdownAnnotation(this.markdown());
        break;
      case 'wordplusmarkdown':
        result = new WordPlusMarkdownAnnotation(this.gloss(), this.markdown());
        break;
    }
    this.dialogRef.close(result);
  }

}
