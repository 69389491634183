import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseAdapterService } from '../database-adapter.service';

@Component({
  selector: 'app-user-settings',
  standalone: true,
  imports: [],
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.scss'
})
export class UserSettingsComponent {
  public id: string | null = null;

  constructor(private dbService: DatabaseAdapterService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.has('userid')) {
        this.id = params.get('userid');
      }
    });
  }
}
