import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { VerseDisplayComponent } from '../verse-display/verse-display.component';
import { BibleNavigatorComponent } from '../bible-navigator/bible-navigator.component';
import { VerseReference } from '@models/VerseReference';
import { SynchronizerService } from '../synchronizer.service';

@Component({
  selector: 'app-editor',
  standalone: true,
  imports: [
    VerseDisplayComponent,
    BibleNavigatorComponent],
  templateUrl: './editor.component.html',
  styleUrl: './editor.component.scss'
})
export class EditorComponent implements OnInit, OnDestroy {
  @Input() reference!: VerseReference;

  constructor(private synchronizerService: SynchronizerService) {
  }

  ngOnInit() {
    this.synchronizerService.updateVerse(this.reference);
  }

  ngOnDestroy(): void {
    const verse = this.synchronizerService.verse$.value;
    console.log("editor destroy", verse);
    if (verse) {
      this.synchronizerService.saveVerseData();
    } else {
      console.error("No verse to update. ngOnDestroy");
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    /// this will inevitably be called more often than is necessary
    /// because it's comparing pointers, not data
    if (changes['reference']) {
      this.synchronizerService.updateVerse(this.reference);
    }
  }

  updateVerseReference(newRef: VerseReference) {
    // console.log("editor updateVerseReference", newRef);
    this.synchronizerService.updateVerse(newRef);
  }

}
