import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DatabaseAdapterService } from '../database-adapter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PROJECT_ROLES, ProjectConfiguration, ProjectId, ProjectRole } from '@models/ProjectConfiguration';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { CANON_NAMES, CanonData, CANONS, getCanon, ALL_BOOK_CODES } from '@models/Canons';
import { Canon, UbsBook, VerseReference } from '@models/VerseReference';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { ServerResponse } from '@models/database-input-output';
import { MatTabsModule } from '@angular/material/tabs';
import { SynchronizerService } from '../synchronizer.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { EditMemberDialogComponent } from '../edit-member-dialog/edit-member-dialog.component';
import { UserId } from '@models/UserProfile';
import { POLYGLOSSIA_LANGUAGES } from '@models/polyglossia-languages';
import { ParsingFormatEditorComponent } from '../parsing-format-editor/parsing-format-editor.component';
import { ProjectTextStyleDirective } from '../project-text-style.directive';
import { combineLatest } from 'rxjs';
import { ParsingFormat } from '@models/parsing-formats/ParsingFormat';

function tenDigitsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let numerals = control.value || "";
    return numerals.length == 10 ? null : { tenDigits: true };
  };
}

function needsChapterValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let chapterHeader = control.value || "";
    return chapterHeader.indexOf('{chapter}') != -1 ? null : { needsChapter: true };
  };
}

@Component({
  selector: 'app-project-settings',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatCheckboxModule, MatCardModule, MatExpansionModule, MatListModule, MatTabsModule, MatSlideToggleModule, ProjectTextStyleDirective],
  templateUrl: './project-settings.component.html',
  styleUrl: './project-settings.component.scss'
})
export class ProjectSettingsComponent implements OnInit, AfterViewInit {
  public project: ProjectConfiguration | undefined;
  form: FormGroup;
  canonNames: Canon[] = CANON_NAMES;
  canons: CanonData[] = CANONS;
  id: ProjectId = '';
  newProject: boolean = false;
  allUserIds: string[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dbService: DatabaseAdapterService,
    private synchronizerService: SynchronizerService,
    public dialog: MatDialog,
    private route: ActivatedRoute) {
    this.form = this.formBuilder.group({
      project_title: ['', Validators.required],
      layout_direction: ['', Validators.required],
      project_description: [''],
      allow_joins: [true],
      font_families: [''],
      font_size: [''],
      footnoteMarkers: ['', Validators.required],
      polyglossiaOtherLanguage: [''],
      numerals: ['', [Validators.required, tenDigitsValidator()]],
      chapterHeader: ['', [Validators.required, needsChapterValidator()]],
    });

    /// add controls for the frequency thresholds
    for (let c of CANON_NAMES) {
      this.form.addControl(c, new FormControl(undefined));
    }

    /// add controls for the book names
    for (let b of ALL_BOOK_CODES) {
      this.form.addControl(b, new FormControl(undefined));
    }

    this.dbService.getUserIds()
      .then((userIds) => {
        this.allUserIds = userIds;
      })
      .catch((error) => {
        console.error("Error getting user IDs", error);
      });

  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    combineLatest([this.route.paramMap, this.synchronizerService.user$]).subscribe(([params, user]) => {
      if (user) {
        /// if a project has been passed as an @Input
        if (this.project === undefined && params.has('projectid')) {
          this.id = params.get('projectid') as ProjectId;
          this.loadDataFromUserData();
        } else {
          console.log("No project ID provided in URL. Must be a new project.");
        }
      }
    });

  }

  resetData() {
    this.loadDataFromUserData();
    this.forceRefresh();
  }

  loadDataFromUserData() {
    let p = this.synchronizerService.user$.value?.project(this.id);
    if (p) {
      /// if p is defined, that means we're editing an existing project
      this.project = ProjectConfiguration.fromRow(p.toObject())
    } else {
      if (this.synchronizerService.user$.value) {
        /// if p is not defined, that means we're creating a new project
        this.project = new ProjectConfiguration(this.id);
        this.project.setMember({ user_id: this.synchronizerService.user$.value?.user_id, user_role: 'admin', power_user: 0 });
        this.newProject = true;
      } else {
        console.error("No user data available to create a new project. (I don't expect this to be possible.)");
      }
    }
    /// now populate default values


    this.form.patchValue({
      id: this.project?.id,
      project_title: this.project?.title,
      project_description: this.project?.description,
      allow_joins: this.project?.allow_joins,
      font_families: this.project?.font_families,
      font_size: this.project?.font_size,
      footnoteMarkers: this.project?.footnoteMarkers ? this.project?.footnoteMarkers.join(' ') : "",
      numerals: this.project?.numerals.join(''),
      chapterHeader: this.project?.chapterHeader,
    });

    for (let c of CANON_NAMES) {
      this.form.get(c)?.setValue(this.project?.getFrequencyThreshold(c));
    }

    /// add controls for the book names
    for (let b of ALL_BOOK_CODES) {
      this.form.get(b)?.setValue(this.project?.bookNames.get(b));
    }

    /// selects need to be handled separately
    this.form.get('layout_direction')?.setValue(this.project?.layout_direction);
    this.form.get('polyglossiaOtherLanguage')?.setValue(this.project?.polyglossiaOtherLanguage);
  }

  forceRefresh() {
    // Refresh the component
    this.router.navigateByUrl('/settings/project/' + this.id, { skipLocationChange: true }).then(() => {
      this.router.navigate([this.router.url]);
    });
  }

  get projectRoles(): ProjectRole[] {
    return PROJECT_ROLES;
  }

  canonsChanged(event: MatSelectionListChange) {
    let selection = new Array<Canon>();
    event.source.selectedOptions.selected.forEach(option => {
      selection.push(option.value);
    });
    if (this.project) {
      this.project.canons = selection;
    }
  }

  save(): void {
    if (this.form.valid && this.project) {
      console.log("Saving project configuration...");

      this.project.title = this.form.get('project_title')?.value;
      this.project.layout_direction = this.form.get('layout_direction')?.value;
      this.project.description = this.form.get('project_description')?.value;
      this.project.allow_joins = this.form.get('allow_joins')?.value;
      this.project.font_families = this.form.get('font_families')?.value;
      this.project.font_size = this.form.get('font_size')?.value;
      this.project.footnoteMarkers = this.form.get('footnoteMarkers')?.value.split(' ');
      this.project.polyglossiaOtherLanguage = this.form.get('polyglossiaOtherLanguage')?.value;
      this.project.numerals = this.form.get('numerals')?.value.split('');
      this.project.chapterHeader = this.form.get('chapterHeader')?.value;

      /// canons should already be current, from the canonsChanged method

      /// update the frequency thresholds
      let thresholds = new Map<Canon, number>();
      CANON_NAMES.forEach(canon => {
        if (this.form.get(canon as string)) {
          thresholds.set(canon, this.form.get(canon as string)?.value);
        }
      });
      this.project.frequency_thresholds = thresholds;

      /// update the book names
      let bookNames = new Map<UbsBook, string>();
      for (let b of this.project.bookNames.keys()) {
        if (this.form.get(b as string)) {
          bookNames.set(b, this.form.get(b as string)?.value);
        }
      }
      // console.log("Book names:", bookNames);
      this.project.bookNames = bookNames;

      // console.log(this.project);

      // Save the updated project configuration using the database service
      this.dbService.saveProjectConfiguration(this.project, this.newProject)
        .then((response: ServerResponse) => {
          console.log("Project save result:", response);

          /// the if is just to keep Typescript happy
          if (this.synchronizerService.user$.value) {
            this.dbService.getUserData(this.synchronizerService.user$.value.user_id)
              .then((user) => {
                if (user) {
                  this.synchronizerService.user$.next(user);
                }
              });
          }

          this.router.navigate(['/myprojects']);
        });
    }
  }

  canonData(canon: Canon): CanonData {
    return getCanon(canon);
  }

  latinBookName(book: UbsBook): string {
    return VerseReference.ubsBookToLatin(book);
  }

  newMember() {
    const dialogRef = this.dialog.open(EditMemberDialogComponent, {
      width: '300px',
      data: { project: this.project }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.project?.setMember(result);
      }
    });
  }

  setMemberRole(userId: string, role: ProjectRole) {
    // console.log("Setting role for user", userId, "to", role);
    let current = this.project?.member(userId);
    if (current) {
      current.user_role = role;
      this.project?.setMember(current);
    }
  }

  setPowerUser(userId: string, powerUser: boolean) {
    // console.log("Setting power user for user", userId, "to", powerUser);
    let current = this.project?.member(userId);
    if (current) {
      current.power_user = powerUser ? 1 : 0;
      this.project?.setMember(current);
    }
  }

  get user_id(): UserId {
    return this.synchronizerService.user$.value?.user_id || "";
  }

  get polyglossia_languages(): string[] {
    return POLYGLOSSIA_LANGUAGES;
  }

  newParsingFormat(canon: Canon) {
    const dialogRef = this.dialog.open(ParsingFormatEditorComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'full-screen-dialog',
      data: {
        canon: canon,
        project: this.project
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let pf = result as ParsingFormat;
        this.project?.setParsingFormat(pf);
      }
    });
  }

  get numerals() {
    return this.form.get('numerals');
  }

  get chapterHeader() {
    return this.form.get('chapterHeader');
  }

  get footnoteMarkers() {
    return this.form.get('footnoteMarkers');
  }

  demoChapterHeader(number: string) {
    const chapterHeader = this.form.get('chapterHeader')?.value || "";
    return chapterHeader.replace('{chapter}', this.demoChangeNumerals(number));
  }

  demoChangeNumerals(str: string) {
    const numerals = this.form.get('numerals')?.value;
    if (numerals === undefined || numerals.length != 10) {
      return "error";
    } else {
      for (let i = 0; i < 10; i++) {
        str = str.replace(new RegExp(i.toString(), "g"), numerals[i]);
      }
      return str;
    }
  }

  demoFootnoteMarkers(howmany: number) {
    const footnoteMarkers = this.form.get('footnoteMarkers')?.value || "";
    let markers = footnoteMarkers.split(' ');
    let result = "";
    for (let i = 0; i < howmany; i++) {
      result += markers[i % markers.length] + ' ';
    }
    return result;
  }

  editParsingFormat(c: Canon, key: string) {
    const dialogRef = this.dialog.open(ParsingFormatEditorComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'full-screen-dialog',
      data: {
        canon: c,
        project: this.project,
        id: key
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let pf = result as ParsingFormat;
        this.project?.setParsingFormat(pf);
      }
    });
  }

  removeParsingFormat(c: Canon, key: string) {
    this.project?.publicationSettings.removeParsingFormat(c, key);
  }

}
