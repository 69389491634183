import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-feedback-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './feedback-message.component.html',
  styleUrl: './feedback-message.component.scss'
})
export class FeedbackMessageComponent {
  @Input() message!: string;
  @Input() level!: 'error' | 'warning' | 'information';

  get messageClass() {
    switch (this.level) {
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'information':
        return 'information';
      default:
        return '';
    }
  }
}
