<h2 mat-dialog-title [innerHTML]="word.text">Parsing</h2>
<mat-dialog-content>

@for( map of parsingSummary; track map; let last = $last ) {
    <table>
        @for( key of map.keys(); track key ) {
            <tr>
                <td>{{ key }}</td>
                <td>{{ map.get(key) }}</td>
            </tr>
        }
        @if( !last ) {
            <tr><td colspan="2"><hr></td></tr>
        }
    </table>
}

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onDismiss()">Dismiss</button>
</mat-dialog-actions>
