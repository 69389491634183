import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { UserProfile } from '@models/UserProfile';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ProjectIdInputDialogComponent } from '../project-id-input-dialog/project-id-input-dialog.component';
import { ProjectConfiguration } from '@models/ProjectConfiguration';
import { SynchronizerService } from '../synchronizer.service';
import { MyprojectsComponent } from '../myprojects/myprojects.component';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CommonModule, MatListModule, MatIconModule, MatButtonModule, RouterModule, MyprojectsComponent],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {

  constructor(
    private router: Router,
    private synchronizerService: SynchronizerService,
    public dialog: MatDialog) {
  }

  get user(): UserProfile | undefined {
    return this.synchronizerService.user$.value;
  }

  get projects(): ProjectConfiguration[] {
    return Array.from(this.user?.projects || []).sort((a: ProjectConfiguration, b: ProjectConfiguration) => b.title.localeCompare(a.title)).reverse();
  }

  newProject(): void {
    const dialogRef = this.dialog.open(ProjectIdInputDialogComponent, {
      width: '400px',
      data: { text: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/settings/project/' + result]);
      }
    });
  }

}
